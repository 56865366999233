<template>
  <div v-if="editMode">
    <div v-if="editable" class="tw-mt-3">
      <v-date-picker v-model="editedValue" :range="options.isMultiple === true" :width="390"></v-date-picker>
    </div>
    <span v-else>{{ formatedDate(field.value, options.format) }}</span>
  </div>

  <div v-else class="tw-whitespace-nowrap">
    <span v-if="isEmpty(fieldValues)" class="empty-field">{{ $t('label.empty') }}</span>

    <template v-else>
      <span v-for="(date, dateIndex) in fieldValues" :key="dateIndex">
        <span v-dompurify-html="formatedDate(date, options.format)" />
        <span v-if="dateIndex < fieldValues.length - 1">&nbsp;-&nbsp;</span>
      </span>
    </template>
  </div>
</template>

<script>
import { formatedDate } from '@/utils/date.util'
import { toArray, isEmpty } from '@/utils/helper.util'

export default {
  name: 'LeadsIndividualFieldsDate',
  props: {
    field: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: false,
      default: () => {},
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    fieldValues() {
      return toArray(this.field.value)
    },
  },
  data: () => ({
    isEmpty,
    formatedDate,
    editedValue: null,
  }),
  created() {
    if (this.field.value) {
      this.editedValue = this.field.value
    }
  },
  watch: {
    editedValue: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) this.$emit('updateField', { [this.field.slug]: newValue })
      },
      deep: true,
      immediate: false,
    },
  },
}
</script>
