<template>
  <div v-if="editMode" class="individual-edit-checkbox tw-mt-3">
    <div v-if="!isMultipleCheckbox"><v-checkbox v-model="editedValue"></v-checkbox></div>

    <div v-else>
      <span class="tw-flex tw-items-center" v-for="(option, index) in possibleValues" :key="index">
        <v-checkbox v-model="selectedOptions" :value="option.value" hide-details @change="setOptions()"></v-checkbox>
        <span>{{ option.label[$i18n.locale] }}</span>
      </span>
    </div>
  </div>

  <div v-else>
    <div v-if="isBooleanValue">
      <UiBooleanIcon :value="field.value" />
    </div>

    <div v-else>
      <span v-if="isEmpty(field.value)" class="empty-field">{{ $t('label.empty') }}</span>

      <span v-for="(currentValue, index) in field.value" :key="index">
        <span v-dompurify-html="currentValue" />
        <span v-if="index < field.value.length - 1">,&nbsp;</span>
      </span>
    </div>
  </div>
</template>

<script>
import { mdiCheckCircle, mdiCloseCircle } from '@mdi/js'
import { isEmpty } from '@/utils/helper.util'
import UiBooleanIcon from '@/components/UI/BooleanIcon.vue'

export default {
  name: 'LeadsIndividualFieldsCheckbox',
  components: {
    UiBooleanIcon,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: false,
      default: () => {},
    },
    possibleValues: {
      type: Array,
      required: false,
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    isEmpty,
    editedValue: null,
    icons: {
      mdiCheckCircle,
      mdiCloseCircle,
    },
    selectedOptions: [],
  }),
  created() {
    if (this.field) {
      this.editedValue = this.field.value
      this.selectedOptions = this.field.value
    }
  },
  computed: {
    isMultipleCheckbox() {
      return this.options.isMultiple
    },
    isBooleanValue() {
      return typeof this.field.value === 'boolean'
    },
  },
  methods: {
    setOptions() {
      this.editedValue = this.selectedOptions
    },
  },
  watch: {
    editedValue: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) this.$emit('updateField', { [this.field.slug]: newValue })
      },
      deep: true,
      immediate: false,
    },
  },
}
</script>

<style lang="scss">
.individual-edit-checkbox {
  .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }
}
</style>
