<template>
  <div>
    <div class="tw-text-bold tw-text-lg tw-mt-10 tw-mb-5">{{ capitalize(lexicon.additionalData) }}</div>
    <div class="additionalData">
      <v-card
        class="tw-flex tw-p-3 tw-mb-6 tw-items-center tw-bg-gray-100 additionalData__entry"
        :class="{ 'tw-interface-dark-grade-1-color': $vuetify.theme.dark }"
        v-for="(additionalDataGroup, groupIndex) in additionalData"
        :key="`group-${groupIndex}`"
      >
        <v-list color="transparent">
          <v-list-item
            v-for="(entryValue, entryKey, entryIndex) in additionalDataGroup"
            :key="`entry-${entryIndex}`"
            class="tw-min-h-full tw-p-0"
          >
            <v-list-item-content class="tw-p-1">
              <div class="tw-grid tw-grid-cols-3 tw-gap-4">
                <span class="tw-col-span-1 tw-text-bold tw-text-gray-500">{{ entryKey }}:&nbsp;</span>
                <span class="tw-col-span-2">{{ entryValue }}</span>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </div>
  </div>
</template>

<script>
import { capitalize } from '@/utils/formatter.util'

export default {
  name: 'LeadsIndividualIdentity',

  props: {
    additionalData: {
      type: Array,
      required: false,
      default: () => [],
    },
    lexicon: {
      type: Object,
    },
  },
  methods: {
    capitalize,
  },
}
</script>
