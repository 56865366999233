<template>
  <div v-if="editMode" class="tw-mt-3">
    <v-select
      v-model="editedValue"
      label="Select"
      item-value="value"
      hide-details="auto"
      :items="possibleValues"
      :item-text="'label.' + $i18n.locale"
      :multiple="options.isMultiple === true"
    >
      <template #item="{ item }">
        <div v-if="item.label">
          {{ item.label[$i18n.locale] }}
        </div>
        <div v-else>
          {{ item.value }}
        </div>
      </template>
    </v-select>
  </div>

  <div v-else>
    <span class="empty-field" v-if="isEmpty(fieldValues)">{{ $t('label.empty') }}</span>

    <template v-else>
      <span v-for="(selection, index) in fieldValues" :key="index">
        <span v-dompurify-html="selection" />
        <span v-if="index < fieldValues.length - 1">,&nbsp;</span>
      </span>
    </template>
  </div>
</template>

<script>
import { toArray, isEmpty } from '@/utils/helper.util'
export default {
  name: 'LeadsIndividualFieldsMultiselect',
  props: {
    field: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: false,
      default: () => {},
    },
    possibleValues: {
      type: Array,
      required: false,
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    fieldValues() {
      return toArray(this.field.value)
    },
  },
  data: () => ({
    isEmpty,
    editedValue: null,
  }),
  created() {
    if (this.field.value) {
      this.editedValue = this.field.value
    }
  },
  watch: {
    editedValue: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) this.$emit('updateField', { [this.field.slug]: newValue })
      },
      deep: true,
      immediate: false,
    },
  },
}
</script>
