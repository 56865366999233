<template>
  <v-menu bottom offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn :x-small="small" icon v-bind="attrs" v-on="on" :disabled="isDisabled">
        <v-icon light>{{ icons.mdiDotsVertical }}</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-for="(action, index) in actions" :key="`action-${index}`" @click="() => {}">
        <v-list-item-title @click="onActionClick(action.slug)">
          <v-icon class="tw-mr-2">{{ action.icon }}</v-icon>
          <span class="action-label" v-dompurify-html="action.label"></span>
        </v-list-item-title>
      </v-list-item>

      <v-list-item v-for="(link, index) in links" :key="`links-${index}`" :href="link.url" target="_blank">
        <v-list-item-title>
          <v-icon class="tw-mr-2">{{ link.icon }}</v-icon>
          {{ link.label }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mdiDotsVertical } from '@mdi/js'

export default {
  name: 'UiExpandableMenu',
  props: {
    actions: {
      type: Array,
      required: false,
      default: () => [],
    },
    links: {
      type: Array,
      required: false,
      default: () => [],
    },
    small: {
      type: Boolean,
      required: false,
      default: () => true,
    },
  },
  data: () => ({
    icons: {
      mdiDotsVertical,
    },
  }),

  computed: {
    isDisabled() {
      return this.actions.length === 0 && this.links.length === 0
    },
  },

  methods: {
    onActionClick(slug) {
      this.$emit('executeAction', slug)
    },
  },
}
</script>

<style lang="scss">
.action-label strong {
  font-weight: 600;
}
</style>
