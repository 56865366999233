<template>
  <v-chip :color="statusOptions.chipColor" :text-color="statusOptions.textColor">
    {{ $t(`leads.list.table.status.${field.value}`) }}
  </v-chip>
</template>

<script>
import { LeadStatus } from '@/config/leads.config'

export default {
  name: 'LeadsIndividualFieldsChip',
  props: {
    field: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    initialStatusColor: {
      textColor: 'black',
      chipColor: 'white',
    },
  }),

  computed: {
    statusOptions() {
      return this.getStatusColor(this.field.value)
    },
  },
  methods: {
    getStatusColor(status) {
      switch (status) {
        case LeadStatus.NEW:
          return {
            textColor: 'white',
            chipColor: '#a678e4',
          }
        case LeadStatus.ACCEPTED:
          return {
            textColor: 'white',
            chipColor: 'blue',
          }
        case LeadStatus.CONVERTED:
          return {
            textColor: 'white',
            chipColor: 'green',
          }
        case LeadStatus.LOST:
          return {
            textColor: 'white',
            chipColor: 'red',
          }
        default:
          return this.initialStatusColor
      }
    },
  },
  watch: {
    editedValue: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) this.$emit('updateField', { [this.field.slug]: newValue })
      },
      deep: true,
      immediate: false,
    },
  },
}
</script>
