<template>
  <v-progress-circular v-if="loading" class="loader tw-m-auto" indeterminate size="48"></v-progress-circular>
  <div v-else class="individual-lead">
    <v-snackbar class="tw-mt-2 sm:tw-mt-4 alert" :timeout="5000" :value="errorMessage !== null" color="error" top>
      <span>{{ $t(`leads.protected.error.${errorMessage}`) }}</span>
    </v-snackbar>

    <v-banner :color="response.layout.primaryColor" class="individual-lead__banner">
      <div v-if="response.layout.logo" class="individual-lead__banner__background">
        <v-img
          class="individual-lead__banner__background__logo"
          contain
          :alt="response.layout.clientName"
          :src="response.layout.logo"
        />
      </div>
    </v-banner>
    <div class="individual-lead__content tw-mb-20">
      <div class="individual-lead__content__message">
        <span class="tw-block">{{ message.text }}</span>
        <v-btn
          v-if="message.cta"
          class="individual-lead__content__message__undo"
          small
          text
          plain
          @click="undoAction"
          >{{ message.cta.label }}</v-btn
        >
      </div>
      <div class="md:tw-w-1/2 tw-mx-auto tw-mb-8 tw-flex tw-justify-center">
        <span v-if="response.lead.status">
          <LeadsIndividualFieldsChip :field="{ value: response.lead.status }" />
        </span>
      </div>
      <v-divider v-if="response.lead.price === undefined" class="tw-my-2" />
      <div v-if="!error && response" class="tw-mx-auto">
        <div v-if="isEmpty(response.lead.links)" class="tw-flex tw-justify-between tw-ml-4 tw-px-2">
          <v-btn
            v-for="(link, key) in response.lead.links"
            class="tw-mr-2"
            target="_blank"
            link
            small
            icon
            :key="key"
            :href="link"
          >
            <v-icon>{{ linkIcon(key) }}</v-icon>
          </v-btn>
        </div>
        <div v-if="response.lead.price >= 0" class="individual-lead__content__price">
          <div v-if="isEditPrice" class="individual-lead__content__header__price tw-px-3">
            <span class="tw-text-xs tw-text-gray-600">{{ $t('leads.protected.labels.price') }}: </span><br />
            <div class="tw-flex tw-justify-center tw-items-center tw-mb-3">
              <ui-input-price-in-cent
                class="tw-flex-col"
                :value-in-cents="modifiedLead.price"
                :currency="response.lead.currency"
                :locale="$i18n.locale"
                @onPriceChange="onPriceChange"
                @clear="onPriceClear"
              />
              <v-btn class="tw-flex-col tw-ml-4" x-large icon color="info" @click="savePrice()">
                <v-icon x-large>{{ icons.mdiContentSaveCheckOutline }}</v-icon>
              </v-btn>
            </div>
          </div>
          <div v-else class="individual-lead__content__header__price">
            <span class="tw-text-xs tw-text-gray-600">{{ $t('leads.protected.labels.price') }}: </span><br />
            <div class="tw-flex tw-justify-center tw-items-center">
              <div class="tw-flex-col tw-text-3xl">{{ leadPriceDisplay }}</div>
              <v-btn class="tw-flex-col tw-ml-4" x-large icon color="info" @click="editPrice()">
                <v-icon x-large>{{ icons.mdiPencilCircleOutline }}</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
        <div class="tw-px-6">
          <div v-if="leadHasSkus">
            <div class="tw-text-bold tw-text-sm tw-text-gray-500 tw-opacity-70">
              {{ capitalize(lexicon.skus) }}
            </div>
            <div>
              <LeadsIndividualFieldsSkus :field="{ value: response.lead.skus }" />
            </div>
            <v-divider class="tw-my-2" />
          </div>
          <div v-if="leadHasLocation">
            <div class="tw-text-bold tw-text-sm tw-text-gray-500 tw-opacity-70">
              {{ capitalize(lexicon.location) }}
            </div>
            {{ response.lead.location.name }}
            <v-divider class="tw-my-2" />
          </div>

          <div v-if="leadHasCompanyLocation">
            <div class="tw-text-bold tw-text-sm tw-text-gray-500 tw-opacity-70">
              {{ capitalize(lexicon.company) }}
            </div>
            {{ response.lead.location.company }}
            <v-divider class="tw-my-2" />
          </div>

          <div v-if="leadHasAddressLocation">
            <div class="tw-text-bold tw-text-sm tw-text-gray-500 tw-opacity-70">
              {{ $t('leads.list.table.headers.address') }}
            </div>
            <span>{{ response.lead.location.address }}</span>
            <v-divider class="tw-my-2" />
          </div>

          <div v-if="!isEmpty(response.lead.identity)" class="tw-mt-5">
            <LeadsIndividualIdentity :identity="response.lead.identity" :lexicon="lexicon"></LeadsIndividualIdentity>
          </div>

          <div v-if="response.lead.fieldsValues" class="tw-my-4">
            <UiTitle
              v-if="response.lead.fieldsValues.length > 0"
              class="tw-text-bold tw-text-lg tw-mt-10 tw-mb-5"
              :title="$t('leads.protected.labels.valueTitle')"
            />
            <div v-for="(fieldValue, index) in response.lead.fieldsValues" :key="index">
              <div class="fields__row tw-mb-2">
                <div class="fields__row__title">{{ fieldValue.name }}</div>
                <component
                  :is="getComponentFieldName(fieldValue.type)"
                  :field="toItem(fieldValue)"
                  :options="getComponentOptions(fieldValue.type)"
                  :locale="$i18n.locale"
                  :editMode="false"
                />
              </div>
              <v-divider />
            </div>
          </div>

          <div v-if="response.lead.additionalData && response.lead.additionalData.length > 0">
            <LeadsIndividualAdditionalData
              :additionalData="response.lead.additionalData"
              :lexicon="lexicon"
            ></LeadsIndividualAdditionalData>
          </div>
        </div>

        <div v-if="privateLeadsUrl" class="tw-text-center tw-my-4">
          <v-btn :href="privateLeadsUrl" text color="primary" target="_blank">
            <span>{{ $t('leads.protected.link.leadList') }}</span>
          </v-btn>
        </div>
      </div>

      <div v-if="response.lead.formName" class="individual-lead__content__footer">
        <v-icon dense>{{ icons.mdiFormSelect }}</v-icon>
        <span class="tw-align-middle tw-ml-1">{{ response.lead.formName }}</span>
      </div>
    </div>

    <UiActions class="individual-leads__actions tw-z-10" large centered>
      <v-btn v-if="getPrimaryAction" class="tw-px-10" color="primary" rounded @click="onActionClick(getPrimaryAction)">
        <span>{{ $t(`leads.protected.actions.${getPrimaryAction}`) }}</span>
      </v-btn>

      <v-btn
        v-if="getSecondaryAction"
        class="tw-px-10"
        @click="onActionClick(getSecondaryAction)"
        rounded
        color="error"
      >
        <span>{{ $t(`leads.protected.actions.${getSecondaryAction}`) }}</span>
      </v-btn>

      <UiExpandableMenu :links="linkList" :small="false" />
    </UiActions>
  </div>
</template>

<script>
import LeadsIndividualFieldsText from '@/components/Leads/Individual/Fields/Text'
import LeadsIndividualFieldsCheckbox from '@/components/Leads/Individual/Fields/Checkbox'
import LeadsIndividualFieldsChip from '@/components/Leads/Individual/Fields/Chip'
import LeadsIndividualFieldsChipList from '@/components/Leads/Individual/Fields/ChipList'
import LeadsIndividualFieldsDate from '@/components/Leads/Individual/Fields/Date'
import LeadsIndividualFieldsFile from '@/components/Leads/Individual/Fields/File'
import LeadsIndividualFieldsRadio from '@/components/Leads/Individual/Fields/Radio'
import LeadsIndividualFieldsMultiselect from '@/components/Leads/Individual/Fields/Multiselect'
import LeadsIndividualFieldsPrice from '@/components/Leads/Individual/Fields/Price'
import LeadsIndividualFieldsSkus from '@/components/Leads/Individual/Fields/Skus'
import LeadsIndividualIdentity from '@/components/Leads/Individual/Identity'
import LeadsIndividualAdditionalData from '@/components/Leads/Individual/AdditionalData'
import UiInputPriceInCent from '@/components/UI/input/PriceInCent.vue'
import {
  mdiAccountCircle,
  mdiPhoneOutline,
  mdiEmailOutline,
  mdiFormSelect,
  mdiPencilCircleOutline,
  mdiContentSaveCheckOutline,
} from '@mdi/js'
import { useLeadToken, undoLeadTokenAction, updateLeadToken } from '@/services/leads.service'

import UiExpandableMenu from '@/components/UI/ExpandableMenu.vue'
import UiActions from '@/components/UI/Actions.vue'
import { LeadAction } from '@/config/leads.config'
import UiTitle from '@/components/UI/Title.vue'
import { isEmpty, formattedPrice, getPropertyByLocale } from '@/utils/helper.util'
import { capitalize } from '@/utils/formatter.util'

export default {
  name: 'ProtectedLead',
  components: {
    UiExpandableMenu,
    LeadsIndividualFieldsText,
    LeadsIndividualFieldsCheckbox,
    LeadsIndividualFieldsChip,
    LeadsIndividualFieldsChipList,
    LeadsIndividualFieldsDate,
    LeadsIndividualFieldsFile,
    LeadsIndividualFieldsRadio,
    LeadsIndividualFieldsMultiselect,
    LeadsIndividualFieldsPrice,
    LeadsIndividualFieldsSkus,
    LeadsIndividualIdentity,
    LeadsIndividualAdditionalData,
    UiInputPriceInCent,
    UiActions,
    UiTitle,
  },
  data: () => ({
    loading: false,
    componentItemsList: [],
    icons: {
      mdiAccountCircle,
      mdiPhoneOutline,
      mdiEmailOutline,
      mdiFormSelect,
      mdiPencilCircleOutline,
      mdiContentSaveCheckOutline,
    },
    isEditPrice: false,
    modifiedLead: {
      price: null,
    },
    isEmpty,
    error: null,
    action: null,
    response: null,
    privateLeadsUrl: null,
    errorMessage: null,
  }),
  async created() {
    this.action = this.$route.query?.action || null
    this.errorMessage = this.$route.query?.error || null
    this.leadToken = this.$route.params.id
    this.setLocale()
    await this.loadLead()
  },
  computed: {
    linkList() {
      const formattedLinks = []

      for (const link in this.response.lead.links) {
        formattedLinks.push({
          icon: this.getIconByValue(link),
          label: this.$t(`leads.list.table.links.${link}`),
          url: this.response.lead.links[link],
        })
      }
      return formattedLinks
    },
    leadPriceDisplay() {
      return formattedPrice({
        number: this.response.lead.price,
        currency: this.response.lead.currency,
        digit: 2,
      })
    },
    message() {
      if (this.response.actions?.undo) {
        return {
          cta: {
            label: this.$t('leads.protected.messages.undo.label'),
            href: this.response.actions.undo,
          },
          text: this.messageText,
        }
      }
      if (this.response.consumedByPeer) {
        return { text: this.$t('leads.protected.messages.acceptedByPeer') }
      }

      return { text: this.messageText }
    },
    leadHasSkus() {
      return this.response.lead.skus?.length
    },
    leadHasLocation() {
      return this.response.lead.location?.name
    },
    leadHasCompanyLocation() {
      return this.response.lead.location?.company
    },
    leadHasAddressLocation() {
      return this.response.lead.location?.address
    },
    messageText() {
      switch (this.action) {
        case LeadAction.ACCEPT:
          return this.response.alreadyConsumed
            ? this.$t('leads.protected.messages.alreadyAccepted')
            : this.$t('leads.protected.messages.accepted')
        case LeadAction.REJECT:
          return this.response.alreadyConsumed
            ? this.$t('leads.protected.messages.alreadyRejected')
            : this.$t('leads.protected.messages.rejected')
        case LeadAction.CONVERTED:
          return this.response.alreadyConsumed
            ? this.$t('leads.protected.messages.alreadyConverted')
            : this.$t('leads.protected.messages.converted')
        case LeadAction.LOST:
          return this.response.alreadyConsumed
            ? this.$t('leads.protected.messages.alreadyLost')
            : this.$t('leads.protected.messages.lost')
        default:
          return this.$t('leads.protected.messages.consultation')
      }
    },
    lexicon() {
      return getPropertyByLocale(this.response.lexicon, this.$i18n.locale)
    },
    getPrimaryAction() {
      if (isEmpty(this.response?.actions)) {
        return null
      }

      const PRIMARY_ACTION = [LeadAction.ACCEPT, LeadAction.CONVERT]

      const actionKeys = Object.keys(this.response.actions)
      return actionKeys.find(action => PRIMARY_ACTION.includes(action))
    },
    getSecondaryAction() {
      if (isEmpty(this.response?.actions)) {
        return null
      }

      const SECONDARY_ACTION = [LeadAction.REJECT, LeadAction.LOSE]

      const actionKeys = Object.keys(this.response.actions)
      return actionKeys.find(action => SECONDARY_ACTION.includes(action))
    },
  },
  methods: {
    getComponentOptions(type) {
      const options = {}

      switch (type) {
        case 'date':
          options.format = 'L LT'
          break
        case 'datepicker':
          options.format = 'L'
          break
        case 'price':
          options.currency = this.response.lead.currency
          break
      }

      return options
    },
    getComponentFieldName(type) {
      let suffix = ''

      switch (type) {
        case 'date':
        case 'datepicker':
          suffix = 'Date'
          break
        case 'checkbox':
          suffix = 'Checkbox'
          break
        case 'select':
        case 'country':
          suffix = 'Multiselect'
          break
        case 'file':
          suffix = 'File'
          break
        case 'ChipList':
          suffix = 'ChipList'
          break
        case 'radio':
          suffix = 'Radio'
          break
        case 'price':
          suffix = 'Price'
          break
        case 'skus':
          suffix = 'Skus'
          break
        default:
          suffix = 'Text'
          break
      }
      return `LeadsIndividualFields${suffix}`
    },
    async undoAction() {
      try {
        await undoLeadTokenAction(this.response.actions.undo)
        this.$router.replace({ query: null })
      } catch (er) {
        // @todo: notify undo fail
        this.$router.replace({ query: null })
      }
    },
    async onActionClick(slug) {
      this.$router.replace({ query: { ...this.$route.query, action: slug } })
    },
    toItem(field) {
      if (field.type === 'file') {
        return {
          slug: field.slug,
          value: { url: field.file.url },
        }
      }
      return {
        slug: field.slug,
        value: field.value,
      }
    },
    linkIcon(key) {
      return key === 'phone' ? this.icons.mdiPhoneOutline : this.icons.mdiEmailOutline
    },
    setLocale() {
      const locale =
        this.$route.query?.locale ||
        navigator.language.substring(0, 2) ||
        navigator.userLanguage.substring(0, 2) ||
        this.$i18n.fallbackLocale

      this.$i18n.locale = Object.keys(this.$i18n.messages).includes(locale) ? locale : this.$i18n.fallbackLocale
      this.$vuetify.lang.current = this.$i18n.locale
    },
    async loadLead() {
      this.loading = true
      try {
        this.response = await useLeadToken(this.leadToken, this.action)
        this.modifiedLead.price = this.response.lead.price
      } catch ({ status, data }) {
        if (status === 410) {
          return this.$router.push({ name: 'ProtectedLeadExpired' })
        }
        if (status === 412) {
          if (data?.type === 'not_owner') return this.$router.replace({ query: { error: 'notOwner' } })
          if (data?.type === 'converted_by_consumer')
            return this.$router.replace({ query: { error: 'convertedByConsumer' } })
        }
        return this.$router.push({ name: 'ProtectedLead404' })
      }
      if (this.response.lead.price === null) this.isEditPrice = true
      if (this.response.leadListUrl) this.privateLeadsUrl = this.response.leadListUrl

      this.loading = false
    },
    editPrice() {
      this.isEditPrice = true
    },
    onPriceChange(newPrice) {
      this.modifiedLead.price = newPrice
    },
    onPriceClear() {
      this.modifiedLead.price = 0
    },
    async savePrice() {
      if (isEmpty(this.modifiedLead)) {
        this.isEditPrice = false
        return
      }

      this.loading = true
      await updateLeadToken(this.leadToken, this.modifiedLead)
      this.loading = false

      await this.loadLead()
      this.isEditPrice = false
    },

    getIconByValue(slug) {
      switch (slug) {
        case 'phone':
          return this.icons.mdiPhoneOutline
        case 'email':
          return this.icons.mdiEmailOutline

        default:
          return this.icons.mdiAccountQuestionOutline
      }
    },
    capitalize,
  },
}
</script>

<style>
.v-banner__wrapper {
  border-bottom: none !important;
  padding: 16px 8px !important;
}
</style>

<style lang="scss" scoped>
.ui-separator {
  @apply tw-mt-2 tw-border-gray-200 dark:tw-border-opacity-10;
}

.fields {
  &__row {
    align-items: center;
    &__title {
      @apply tw-text-sm;
      opacity: 0.5;
    }
  }
}
.row-value {
  overflow: hidden;
  text-overflow: ellipsis;
}

.individual-lead {
  &__banner {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    &__background {
      @apply tw-h-24;
      display: flex;

      &__logo {
        @apply tw-w-40 tw-mx-auto;
        flex: 0 1 auto !important;
        margin-bottom: 3.125rem;
      }
    }
  }

  &__content {
    @apply md:tw-w-1/2 tw-mx-auto tw-mb-7;
    color: #333333;
    background-color: white;
    padding-top: 2rem;
    margin-top: -3.125rem;
    z-index: 1;
    position: relative;

    &__message {
      @apply tw-font-bold tw-text-center tw-mb-2;

      &__undo {
        @apply tw-font-semibold tw-mx-auto tw-bg-gray-200 tw-text-center tw-py-1 tw-my-4;
        border: #d2d2d2 solid 1px;
      }
    }

    &__price {
      @apply tw-font-semibold tw-mx-auto tw-bg-gray-200 tw-text-center tw-py-1 tw-mb-6 tw-shadow-sm;
      border-top: #d2d2d2 solid 1px;
      border-bottom: #d2d2d2 solid 1px;
    }

    &__footer {
      @apply tw-text-center tw-py-5;

      background-color: $white-catskill !important;
      color: #999;
    }
  }

  &__actions {
    width: 100%;
    left: inherit;
  }
}
</style>
