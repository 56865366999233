<template>
  <v-icon :color="getOptinColor">{{ getOptinIcon }}</v-icon>
</template>

<script>
import { mdiCheckCircle, mdiCloseCircle } from '@mdi/js'
export default {
  name: 'UiBooleanIcon',
  data: () => ({
    icons: {
      mdiCheckCircle,
      mdiCloseCircle,
    },
  }),
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    getOptinIcon() {
      return this.value ? this.icons.mdiCheckCircle : this.icons.mdiCloseCircle
    },
    getOptinColor() {
      return this.value ? 'green' : 'red'
    },
  },
}
</script>
