<template>
  <div class="skus">
    <div v-if="editMode" class="tw-mt-3">
      <v-combobox v-model="editedValue" multiple outlined chips hide-details="auto"></v-combobox>
    </div>

    <div v-else>
      <span class="empty-field" v-if="isEmpty(fieldValues)">{{ $t('label.empty') }}</span>
      <div v-else class="tw-flex tw-flex-row tw-items-center">
        <div class="tw-flex-auto">
          <v-chip
            small
            class="skus__chip tw-mr-1"
            v-for="(chip, index) in fieldValues"
            :key="index"
            @click="copySkus(chip)"
          >
            <span :title="chip" class="skus__chip__text">{{ chip }}</span>
          </v-chip>
        </div>
        <v-btn color="primary" rounded icon x-small @click="copySkus(fieldValues.join(', '))">
          <v-icon>
            {{ icons.mdiContentCopy }}
          </v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { highlightText } from '@/utils/formatter.util'
import { toArray, isEmpty } from '@/utils/helper.util'
import { mdiContentCopy } from '@mdi/js'

export default {
  name: 'LeadsIndividualFieldsSkus',
  props: {
    field: {
      type: Object,
      required: true,
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    fieldValues() {
      return toArray(this.field.value)
    },
  },
  data: () => ({
    highlightText,
    isEmpty,
    icons: {
      mdiContentCopy,
    },
    editedValue: null,
  }),
  created() {
    if (this.field.value) {
      this.editedValue = this.field.value
    }
  },
  watch: {
    editedValue: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) this.$emit('updateField', { [this.field.slug]: newValue })
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      setAlert: 'backoffice/setAlert',
    }),
    async copySkus(skus) {
      try {
        await navigator.clipboard.writeText(skus)
        this.setAlert({
          color: 'success',
          text: this.$t('leads.individual.skus.copy.success'),
        })
      } catch ($e) {
        this.setAlert({
          color: 'error',
          text: this.$t('leads.individual.skus.copy.fail'),
        })
      }
    },
  },
}
</script>

<style lang="scss">
.skus {
  &__chip {
    margin-top: 5px;

    &__text {
      max-width: 500px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .v-chip__content {
        display: inline !important;
      }
    }
  }
}
</style>
