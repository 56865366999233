<template>
  <div v-if="editMode" class="individual-edit-radio tw-mt-3">
    <v-radio-group v-model="editedValue">
      <v-radio
        v-for="(option, index) in possibleValues"
        :key="index"
        :label="option.label[$i18n.locale]"
        :value="option.value"
      ></v-radio>
    </v-radio-group>
  </div>

  <div v-else>
    <span v-if="isEmpty(field.value)" class="empty-field">{{ $t('label.empty') }}</span>
    <span v-else v-dompurify-html="field.value" />
  </div>
</template>

<script>
import { mdiCheckCircle, mdiCloseCircle } from '@mdi/js'
import { isEmpty } from '@/utils/helper.util'

export default {
  name: 'LeadsIndividualFieldsRadio',
  props: {
    field: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: false,
      default: () => {},
    },
    possibleValues: {
      type: Array,
      required: false,
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    isEmpty,
    icons: {
      mdiCheckCircle,
      mdiCloseCircle,
    },
    editedValue: null,
  }),
  created() {
    if (this.field.value) {
      this.editedValue = this.field.value
    }
  },
  computed: {
    isMultipleCheckbox() {
      return this.options.isMultiple
    },
  },
  watch: {
    editedValue: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) this.$emit('updateField', { [this.field.slug]: newValue })
      },
      deep: true,
      immediate: false,
    },
  },
}
</script>

<style lang="scss">
.individual-edit-radio {
  .v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
    margin-bottom: 0;
  }

  .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }
}
</style>
