<template>
  <div v-if="editMode" class="tw-my-3 d-flex">
    <span class="empty-field tw-mt-4" v-if="(hasNoFile && !this.loading) || (fileDeleted && file)">{{
      $t('label.empty')
    }}</span>
    <v-btn
      v-else-if="!isEmpty(field.value.url) && !loading && !file"
      class="tw-mt-3"
      x-small
      icon
      outlined
      color="primary"
      @click="goTo(field.value.url)"
      :title="$t('leads.list.table.actions.image.title')"
    >
      <v-icon light>{{ icons.mdiMagnifyPlusOutline }}</v-icon>
    </v-btn>
    <v-progress-circular v-if="loading" class="tw-mt-3" indeterminate color="primary"></v-progress-circular>
    <v-icon v-if="!loading && file && response.success" class="tw-mt-3" large color="primary"
      >{{ icons.mdiCheck }}
    </v-icon>
    <v-icon v-if="!loading && response.error" class="tw-mt-3" large color="error"
      >{{ icons.mdiAlertCircleOutline }}
    </v-icon>

    <v-file-input
      class="tw-ml-3"
      solo
      flat
      outlined
      :label="$t('leads.individual.edit.file.label')"
      ref="file"
      v-model="file"
      hide-details="auto"
      :clearable="false"
      :prepend-inner-icon="icons.mdiUpload"
      :prepend-icon="null"
      :error-messages="errorMessages"
      @change="onInput"
    >
      <v-btn x-small>replace</v-btn>
    </v-file-input>
    <v-btn
      v-if="!isEmpty(field.value) && !fileDeleted && !loading && !file"
      class="tw-mx-4 tw-mt-2"
      icon
      @click="deleteFile"
    >
      <v-icon :color="deleteIconColor"> {{ icons.mdiTrashCanOutline }}</v-icon>
    </v-btn>
    <v-btn v-else-if="file && response.success" class="tw-mx-4 tw-mt-2" icon @click="cancelUpload">
      <v-icon color="error"> {{ icons.mdiClose }}</v-icon>
    </v-btn>

    <UiDialog :dialog="preDeletefile" scrollable @close="preDeletefile = false" :max-width="500">
      <template v-slot:header>
        <UiTitle :title="$t('forms.individual.translations.cards.default.modal.title')" />
      </template>
      <template v-slot:body>
        <div class="tw-px-4 tw-py-6 sm:tw-px-6">
          {{ $t('leads.individual.edit.file.modal.body') }}
        </div>
      </template>
      <template v-slot:actions>
        <v-btn type="submit" rounded text @click="preDeletefile = false">
          {{ $t('button.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn type="submit" rounded color="primary" @click="deleteFile">
          {{ $t('button.confirm') }}
        </v-btn>
      </template>
    </UiDialog>
  </div>

  <div v-else>
    <span v-if="isEmpty(field.value)" class="empty-field">{{ $t('label.empty') }}</span>

    <v-btn
      v-else-if="!isEmpty(field.value.url)"
      x-small
      outlined
      color="primary"
      @click="goTo(field.value.url)"
      :title="$t('leads.list.table.actions.image.title')"
    >
      <v-icon light>{{ icons.mdiMagnifyPlusOutline }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
import {
  mdiMagnifyPlusOutline,
  mdiUpload,
  mdiCheck,
  mdiAlertCircleOutline,
  mdiTrashCanOutline,
  mdiClose,
} from '@mdi/js'
import { isEmpty } from '@/utils/helper.util'
import { formatBytes } from '@/utils/utilities.util'
import { uploadFile } from '@/services/leads.service'
import UiDialog from '@/components/UI/Dialog.vue'
import UiTitle from '@/components/UI/Title.vue'

export default {
  name: 'LeadsIndividualFieldsFile',
  components: {
    UiDialog,
    UiTitle,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentForm: {
      // @TODO: Remove if possible (only field who need this)
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data: () => ({
    isEmpty,
    icons: {
      mdiMagnifyPlusOutline,
      mdiUpload,
      mdiCheck,
      mdiAlertCircleOutline,
      mdiTrashCanOutline,
      mdiClose,
    },
    file: null,
    loading: false,
    response: {
      success: false,
      error: null,
    },
    preDeletefile: false,
    deleteIconColor: null,
    fileDeleted: false,
    editedValue: null,
  }),
  created() {
    if (this.field.value) {
      this.editedValue = this.field.value.id
    }
  },
  methods: {
    goTo(url) {
      window.open(url, '_blank')
    },
    async onInput() {
      this.loading = true
      this.response = {
        success: false,
        error: null,
      }
      this.fileDeleted = false
      try {
        let formData = new FormData()
        formData.append('file', this.file)
        const { id } = await this.postFile(formData)
        this.editedValue = { id }
        this.response.success = true
      } catch (error) {
        this.response.success = false
        this.response.error = error.data
      }
      this.loading = false
    },
    async postFile(file) {
      await new Promise(resolve => setTimeout(resolve, 2000))
      return uploadFile(this.currentForm.clientId, file, this.currentForm.id, 'lead')
    },
    deleteFile() {
      if (!this.preDeletefile) {
        this.preDeletefile = true
        return
      }
      this.field.value = null
      this.file = null
      this.editedValue = null
      this.fileDeleted = true
      this.preDeletefile = false
    },
    cancelUpload() {
      this.file = null
      this.editedValue = this.field.value
    },
  },
  computed: {
    errorMessages() {
      const errors = []
      if (this.file) {
        !this.fileValidation.format &&
          errors.push(
            this.$t('leads.individual.edit.file.errors.fileFormat', {
              format: this.fileExtension.toUpperCase(),
              allowedFormats: this.displayedFileFormats,
            })
          )

        !this.fileValidation.size &&
          errors.push(
            this.$t('leads.individual.edit.file.errors.fileSize', {
              size: formatBytes(this.fileSize, this.$i18n.locale),
              maxSize: formatBytes(this.maxFileSize, this.$i18n.locale),
            })
          )

        !this.fileValidation.notEmpty && errors.push(this.$t('leads.individual.edit.file.errors.fileEmpty'))
      }
      return errors
    },
    fileValidation() {
      return {
        format: this.supportedFileFormats.length ? this.supportedFileFormats.includes(this.fileExtension) : true,
        size: this.maxFileSize ? this.fileSize <= this.maxFileSize : true,
        notEmpty: this.fileSize > 0,
      }
    },
    displayedFileFormats() {
      return this.response?.error?.expectedExtensions?.map(ext => ext.toUpperCase()).join(', ')
    },
    supportedFileFormats() {
      return this.response?.error?.expectedExtensions || []
    },
    fileExtension() {
      return this.file?.name.split('.').pop().toLowerCase() || null
    },
    fileSize() {
      return this.file?.size || null
    },
    maxFileSize() {
      return this.response?.error?.expectedSize || null
    },
    hasNoFile() {
      return this.isEmpty(this.field.value) && !this.file
    },
  },
  watch: {
    editedValue: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) this.$emit('updateField', { [this.field.slug]: newValue.id })
      },
      deep: true,
    },
  },
}
</script>
