<template>
  <div v-if="editMode" class="chip-list tw-mt-3">
    <v-combobox v-model="editedValue" multiple outlined chips hide-details="auto" :items="possibleValues"></v-combobox>
  </div>

  <div v-else class="chip-list">
    <span class="empty-field" v-if="isEmpty(fieldValues)">{{ $t('label.empty') }}</span>
    <span v-else>
      <v-chip small class="chip-list__chip" v-for="(chip, index) in fieldValues" :key="index">
        <span v-dompurify-html="chip" />
      </v-chip>
    </span>
  </div>
</template>

<script>
import { toArray, isEmpty } from '@/utils/helper.util'

export default {
  name: 'LeadsIndividualFieldsChipList',
  props: {
    field: {
      type: Object,
      required: true,
    },
    possibleValues: {
      type: Array,
      require: true,
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    fieldValues() {
      return toArray(this.field.value)
    },
  },
  data: () => ({
    isEmpty,
    editedValue: null,
  }),
  created() {
    if (this.field.value) {
      this.editedValue = this.field.value
    }
  },
  watch: {
    editedValue: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) this.$emit('updateField', { [this.field.slug]: newValue })
      },
      deep: true,
      immediate: false,
    },
  },
}
</script>

<style lang="scss">
.chip-list {
  &__chip {
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }

  .v-select.v-input--dense .v-chip {
    border-radius: 12px;
    height: 24px;
    font-size: 12px;
  }
}
</style>
