<template>
  <div v-if="editMode" class="tw-mt-3">
    <v-text-field
      v-model="editedValue"
      solo
      flat
      outlined
      hide-details="auto"
      :type="inputType"
      :disabled="!editable"
      :placeholder="isEmpty(field.value) ? $t('label.empty') : field.value.toString()"
    />
  </div>

  <div v-else class="tw-whitespace-pre-line">
    <span v-if="isEmpty(field.value)" class="empty-field">{{ $t('label.empty') }}</span>
    <span v-else v-dompurify-html="field.value" />
  </div>
</template>

<script>
import { isEmpty } from '@/utils/helper.util'

export default {
  name: 'LeadsIndividualFieldsText',
  props: {
    field: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: false,
      default: () => {},
    },
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    isEmpty,
    editedValue: null,
  }),
  created() {
    if (this.field.value) {
      this.editedValue = this.field.value
    }
  },
  computed: {
    inputType() {
      return this.options.inputType || 'text'
    },
    formattedEditedValue() {
      return this.options?.inputType === 'number' ? this.parseNumber(this.editedValue) : this.editedValue
    },
  },
  methods: {
    parseNumber(value) {
      const parsedValue = parseFloat(value)
      return isNaN(parsedValue) ? null : parsedValue
    },
  },
  watch: {
    editedValue: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$emit('updateField', { [this.field.slug]: this.formattedEditedValue })
        }
      },
      deep: true,
      immediate: false,
    },
  },
}
</script>
