<template>
  <div>
    <div v-if="!isEmpty(identity)" class="identity">
      <v-list class="tw-w-full" color="transparent">
        <UiTitle class="tw-text-bold tw-text-lg tw-mb-5" :title="capitalize(lexicon.identity)" />
        <template v-for="(identityValue, identityKey, identityIndex) in identity">
          <v-list-item class="tw-min-h-full tw-p-0" :key="identityIndex">
            <v-list-item-content class="tw-p-0 identity__item">
              <div class="tw-flex tw-flex-col tw-mb-2">
                <span class="identity__item__title">{{ $t(`leads.individual.identity.${identityKey}`) }}</span>
                <!-- Simple identity -->
                <span v-if="identityKey !== 'optin'" class="tw-whitespace-pre-wrap identity__item__text">{{
                  getFormattedIdentityValue(identityKey, identityValue)
                }}</span>
                <!-- Optin identity -->
                <template v-if="identityKey === 'optin'">
                  <span
                    class="tw-whitespace-pre-wrap identity__item__text"
                    v-for="(optinValue, optinKey, optinIndex) in identityValue"
                    :key="optinIndex"
                    >{{ $t(`leads.individual.identity.optinDetail.${optinKey}`) }}
                    <UiBooleanIcon :value="optinValue" />
                  </span>
                </template>
              </div>
            </v-list-item-content>
          </v-list-item>

          <v-divider class="ui-separator" :key="`${identityIndex}-divider`"></v-divider>
        </template>
      </v-list>
    </div>
  </div>
</template>

<script>
import UiTitle from '@/components/UI/Title.vue'
import UiBooleanIcon from '@/components/UI/BooleanIcon.vue'
import { isEmpty } from '@/utils/helper.util'
import { capitalize } from '@/utils/formatter.util'
export default {
  name: 'LeadsIndividualIdentity',
  components: {
    UiTitle,
    UiBooleanIcon,
  },
  data: () => ({
    isEmpty,
  }),
  props: {
    identity: {
      type: Object,
      required: false,
      default: () => {},
    },
    lexicon: {
      type: Object,
    },
  },
  methods: {
    getFormattedIdentityValue(identityKey, identityValue) {
      switch (identityKey) {
        case 'address':
          // eslint-disable-next-line no-case-declarations
          let formattedAddress = `${identityValue.street1 || ''} ${identityValue.street2 || ''}`
          if (identityValue.city) formattedAddress += `\n${identityValue.city}`
          if (identityValue.zipcode) formattedAddress += `, ${identityValue.zipcode}`
          if (identityValue.country) formattedAddress += `, ${identityValue.country}`

          return formattedAddress.trim()
        case 'optin':
          // eslint-disable-next-line no-case-declarations
          let formattedOptin = ''

          if (this.isBooleanValue(identityValue.email)) {
            formattedOptin += `${this.$t('leads.individual.identity.optinDetails.email')} ${this.getOptinIcon(
              identityValue.email
            )}\n`
          }
          if (this.isBooleanValue(identityValue.phone)) {
            formattedOptin += `${this.$t('leads.individual.identity.optinDetails.phone')} ${this.getOptinIcon(
              identityValue.email
            )}\n`
          }
          if (this.isBooleanValue(identityValue.partners)) {
            formattedOptin += `${this.$t('leads.individual.identity.optinDetails.partners')} ${this.getOptinIcon(
              identityValue.email
            )}\n`
          }

          return formattedOptin.trim()
        default:
          return identityValue
      }
    },
    capitalize,
  },
}
</script>

<style lang="scss" scoped>
.ui-separator {
  @apply tw-my-1 tw-border-gray-200 dark:tw-border-opacity-10;
}
.identity {
  &__item {
    &__title {
      @apply tw-text-sm;
      opacity: 0.5;
    }
  }
}
</style>
