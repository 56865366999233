<template>
  <div v-if="editMode" class="tw-mt-3 d-flex">
    <ui-input-price-in-cent
      :value-in-cents="editedValue.value"
      :currency="field.value.currency"
      :disabled="!editable"
      :locale="locale"
      @onPriceChange="onPriceChange"
      @clear="onPriceClear"
    />
  </div>

  <div v-else>
    <span class="empty-field" v-if="!parsedValue">{{ $t('label.empty') }}</span>
    <span v-else v-dompurify-html="parsedValue" />
  </div>
</template>

<script>
import { isEmpty, formattedPrice } from '@/utils/helper.util'
import UiInputPriceInCent from '@/components/UI/input/PriceInCent.vue'

export default {
  name: 'LeadsIndividualFieldsPrice',
  props: {
    field: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
    locale: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      required: false,
      default: () => {},
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    UiInputPriceInCent,
  },
  data: () => ({
    priceRef: null,
    editedValue: {
      value: null,
    },
  }),
  created() {
    const itemValue = this.field.value?.value
    if (itemValue) {
      this.editedValue.value = itemValue
    }
  },
  computed: {
    parsedValue() {
      if (!this.field.value?.value) return undefined
      const number = this.field.value.value
      return formattedPrice({ number, currency: this.field.value.currency })
    },
  },
  methods: {
    isEmpty,
    onPriceChange(price) {
      this.editedValue = { value: price }
      this.sendPrice()
    },
    onPriceClear() {
      this.editedValue.value = 0
      this.sendPrice()
    },
    sendPrice() {
      this.$emit('updateField', { [this.field.slug]: this.editedValue })
    },
  },
}
</script>
